import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Avatar,
  Button,
  Chip,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/system";
import WorkIcon from "@mui/icons-material/Work";
import DescriptionIcon from "@mui/icons-material/Description";
import LanguageIcon from "@mui/icons-material/Language";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EditIcon from "@mui/icons-material/Edit";

import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Sidebar from "./sidebar";
import { Facebook, LinkedIn } from "@mui/icons-material";
import axios from "axios";
const GradientHeader = styled(Box)(({ theme }) => ({
  background: "linear-gradient(90deg, #FFD1E8, #FFC7A1)",
  height: "150px",
  borderRadius: "8px 8px 0 0",
}));

const ProfileContainer = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  borderRadius: "8px",
  backgroundColor: "#fff",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  border: "3px solid #fff",
  marginTop: "-60px",
}));

const UserProfile = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [profileImage, setProfileImage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [text, setText] = useState("");
  const [name, setName] = useState("");
  const [textError, setTextError] = useState(false);
  const [location, setLocation] = useState("");
  const [aboutCompany, setAboutCompany] = useState("");
  const [services, setServices] = useState("");
  const [aboutCompanyType, setAboutCompanyType] = useState(""); // typing or upload
  const [servicesType, setServicesType] = useState(""); // typing or upload
  const [mxDomain, setMxDomain] = useState("");
  const [facebookURL, setFacebookURL] = useState("");
  const [profileData, setProfileData] = useState(null);
  const [websiteURL, setWebsiteURL] = useState("");
  const [linkedinURL, setLinkedinURL] = useState("");
  const [isEditOpen, setIsEditOpen] = useState(false); // State for edit profile dialog
  const [isVisit, setIsVist] = useState("");
  const [errors, setErrors] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    const isFirstVisit = localStorage.getItem("firstVisit");
    setIsVist(isFirstVisit);
    if (isFirstVisit === "true") {
      setIsEditOpen(true);
    }
  }, []);

  const handleFileChange = (e, fileSetter) => {
    fileSetter(e.target.files[0]);
  };

  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };
  const handleBuyOption = () => {
    console.log("Buy Option clicked");
    // Implement logic for Buy Option here (e.g., redirect to a payment page)
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "aboutCompanyType":
        setAboutCompanyType(value);
        break;
      case "servicesType":
        setServicesType(value);
        break;
      default:
        break;
    }
  };

  const fetchProfileData = async () => {
    

    try {
      // Retrieve the token from localStorage
      const token = localStorage.getItem("accesstoken");
  
      // Make the GET request with headers and Bearer token
      const response = await axios.get(
        "https://marketing.jinnhire.in/api/tenants/",
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the Bearer token
            "Content-Type": "application/json", // Optional: specify content type
          },
        }
      );
  
      // Handle the response
      if (response.status === 200) {
        setProfileData(response.data); // Update state with fetched data
      }
    } catch (error) {
      setError("Error fetching profile data"); // Set error if fetch fails
      console.error("Error fetching profile data:", error);
    }
  };

  // useEffect to call fetchProfileData when the component mounts
  useEffect(() => {
    fetchProfileData();
  }, []);

  const handleSave = async () => {
    const newErrors = {};
  
    // Validation logic for 'name' field
    if (!name.trim()) {
      newErrors.name = "Company Name is required.";
    }
  
    // Validation logic for 'location' field
    if (!location.trim()) {
      newErrors.location = "Location is required.";
    }
  
    // Validation logic for 'aboutCompany' field based on the selected type
    if (aboutCompanyType === "typing" && !aboutCompany.trim()) {
      newErrors.aboutCompany = "About Company is required.";
    } else if (aboutCompanyType === "upload" && !file1) {
      // Check if file1 (About Company document) is missing
      newErrors.aboutCompany = "Please upload a file for About Company.";
    }
  
    // Validation logic for 'services' field based on the selected type
    if (servicesType === "typing" && !services.trim()) {
      newErrors.services = "Services Provided is required.";
    } else if (servicesType === "upload" && !file2) {
      // Check if file2 (Services document) is missing
      newErrors.services = "Please upload a file for Services Provided.";
    }
  
    // Check if MX domain is provided
    if (!mxDomain) {
      newErrors.mxDomain = "MX Domain is required.";
    }
  
    // If no errors, save the profile
    if (Object.keys(newErrors).length === 0) {
      try {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("business_phone", phoneNumber);
        formData.append("address", location);
  
        if (aboutCompanyType === "typing") {
          formData.append("about_text", aboutCompany);
        }
        if(aboutCompanyType==="upload"){
          formData.append("about_pdf", file1 )
        }
        if (servicesType === "typing") {
          formData.append("services_text", aboutCompany);
        }
        if(servicesType==="upload"){
          formData.append("services_pdf", file2 )
        }
        
        // Other fields
        formData.append("mx_domain", mxDomain);
        formData.append("fb_url", facebookURL);
        formData.append("websiteURL", websiteURL);
        formData.append("linkedin_url", linkedinURL);
  
        // Post the form data to the API
        const response = await axios.post(
          "https://marketing.jinnhire.in/api/tenants/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",  // Correct content type for form data
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (response.status === 200) {
          console.log("Profile saved successfully:", response.data);
          handleEditClose(); 
        }
      } catch (error) {
        console.error("Error saving profile:", error);
      }
    } else {
      // Set the error messages
      setErrors(newErrors);
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update the state based on the name of the input field
    switch (name) {
      case "profileImage":
        setProfileImage(value);
        break;
      case "phoneNumber":
        setPhoneNumber(value);
        break;
      case "name":
        setName(value);
        break;
      case "location":
        setLocation(value);
        break;
      case "aboutCompany":
        setAboutCompany(value);
        break;
      case "services":
        setServices(value);
        break;
      case "aboutCompanyType":
        setAboutCompanyType(value);
        break;
      case "servicesType":
        setServicesType(value);
        break;
      case "mxDomain":
        setMxDomain(value);
        break;
      case "facebookURL":
        setFacebookURL(value);
        break;
      case "websiteURL":
        setWebsiteURL(value);
        break;
      case "linkedinURL":
        setLinkedinURL(value);
        break;
      default:
        break;
    }

    setErrors(" "); // Clear errors (if any)
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);  // Update the state with the file data
      };
      reader.readAsDataURL(file);  // Convert the image to a data URL
    }
  };

  const handleEditOpen = () => {
    setIsEditOpen(true);
    localStorage.setItem("firstVisit", false);
  };
  const handleEditClose = () => {
    setIsEditOpen(false);
    localStorage.setItem("firstVisit", false);
  };

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />

      <ProfileContainer
        sx={{
          width: sidebarOpen
            ? { xs: "0", sm: "70%" }
            : { xs: "100%", sm: "90%" },
          ml: sidebarOpen ? { xs: "0", sm: "290px" } : { xs: "0", sm: "90px" },
        }}
      >
        {/* Header Section */}
        <GradientHeader />
        <Box textAlign="left" padding="16px">
          <StyledAvatar src={profileImage} alt="User Avatar" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="h5" fontWeight="bold" mt={2}>
                {profileData?.name}
              </Typography>
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                textAlign={"left"}
                mt={1}
              >
                <LocationOnIcon color="action" fontSize="small" />
                <Typography variant="body2" color="textSecondary">
                  {profileData?.location}
                </Typography>
              </Box>
              <Typography variant="subtitle1" mt={1}>
                {profileData?.phoneNumber}
              </Typography>
            </Box>

            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "fit-content",
                height: "60px",
                borderRadius: "10px",
                textTransform: "none",
              }}
              onClick={handleEditOpen}
            >
              Edit Profile{" "}
              <EditIcon
                sx={{ marginLeft: "10px", width: "20px", height: "20px" }}
              />
            </Button>
          </Box>
        </Box>

        {/* Skills Section */}
        <Box px={3} py={2}>
          <Typography
            variant="h6"
            fontWeight="normal"
            sx={{ color: "#324A7F" }}
          >
            Services*
          </Typography>
          <Card sx={{ my: 2 }}>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar sx={{ backgroundColor: "#E34C1A" }}>
                    <ManageAccountsIcon />
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {profileData?.services}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        {/* Employment History Section */}
        <Box px={3} py={2}>
          <Typography variant="h6" fontWeight="bold">
            About Company *
          </Typography>

          <Card sx={{ my: 2 }}>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar sx={{ backgroundColor: "#66CCFF" }}>
                    <WorkIcon />
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {profileData?.aboutCompany}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        <Box px={3} py={2}>
          <Typography variant="h6" fontWeight="bold">
            Website URL:
          </Typography>

          <Card sx={{ my: 2 }}>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar sx={{ backgroundColor: "#FF7A00" }}>
                    <LanguageIcon sx={{ color: "white" }} />
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {profileData?.websiteURL}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box px={3} py={2}>
          <Typography variant="h6" fontWeight="bold">
            LinkedIn URL
          </Typography>

          <Card sx={{ my: 2 }}>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar sx={{ backgroundColor: "#0A66C2" }}>
                    <LinkedIn sx={{ color: "#FFF" }} />
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {profileData?.linkedinURL}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box px={3} py={2}>
          <Typography variant="h6" fontWeight="bold">
            Facebook URL
          </Typography>

          <Card sx={{ my: 2 }}>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar sx={{ backgroundColor: "#1877F2" }}>
                    <Facebook sx={{ color: "#FFF" }} />
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {profileData?.facebookURL}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </ProfileContainer>

      {/* Edit Profile Dialog */}
      <Dialog
        open={isEditOpen}
        onClose={handleEditClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            background: "linear-gradient(90deg, #FF9A8B, #FFC7A1)",
            color: "#fff",
            padding: "16px",
            textAlign: "center",
            borderRadius: "8px 8px 0 0",
          }}
        >
          Edit Profile
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#f8f8f8",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="h6"
            fontWeight="normal"
            mt={1}
            textAlign={"center"}
            style={{ fontSize: "16px" }}
          >
            Upload Profile Picture
          </Typography>
          <Box
            textAlign="center"
            mt={5}
            p={3}
            sx={{
              width: "30%",
              display: "flex",
              flexDirection: "row",
              justifySelf: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
          <StyledAvatar
  src={profileImage || null}  // Use the profileImage if it exists
  sx={{
    position: "relative",
    width: "120px",
    height: "120px",
    objectFit: "cover",
  }}
>
  {!profileImage && <AddPhotoAlternateIcon fontSize="large" />}
</StyledAvatar>

            <label
              htmlFor="upload-image"
              style={{ position: "absolute", bottom: "5px", right: "5px" }}
            >
              <input
                accept="image/*"
                id="upload-image"
                type="file"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />
              <IconButton
                color="primary"
                component="span"
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.6)",
                  borderRadius: "50%",
                  right: "75px",
                  padding: "6px",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                  },
                }}
              >
                <AddPhotoAlternateIcon />
              </IconButton>
            </label>
          </Box>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Company Name*"
                  name="name"
                  fullWidth
                  variant="outlined"
                  value={name}
                  onChange={handleInputChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Phone Number"
                  name="phoneNumber"
                  fullWidth
                  variant="outlined"
                  value={phoneNumber}
                  onChange={handleInputChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Location"
                  name="location"
                  fullWidth
                  variant="outlined"
                  value={location}
                  onChange={handleInputChange}
                  helperText={errors.location}
                  error={!!errors.location}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="MX Domain"
                  name="mxDomain"
                  fullWidth
                  variant="outlined"
                  value={mxDomain}
                  onChange={handleInputChange}
                  helperText={errors.mxDomain}
                  error={!!errors.mxDomain}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Facebook URL"
                  name="facebookURL"
                  fullWidth
                  variant="outlined"
                  value={facebookURL}
                  onChange={handleInputChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Website URL"
                  name="websiteURL"
                  fullWidth
                  variant="outlined"
                  value={websiteURL}
                  onChange={handleInputChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="LinkedIn URL"
                  name="linkedinURL"
                  fullWidth
                  variant="outlined"
                  value={linkedinURL}
                  onChange={handleInputChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                  }}
                />
              </Grid>
              {/* About Company Section */}
              <Grid item xs={12}>
                <Typography variant="subtitle1">About Company*</Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="aboutCompanyType"
                    value={aboutCompanyType}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="typing"
                      control={<Radio />}
                      label="Typing"
                    />
                    <FormControlLabel
                      value="upload"
                      control={<Radio />}
                      label="Upload File"
                    />
                  </RadioGroup>
                </FormControl>
                {aboutCompanyType === "typing" ? (
                  <TextField
                    label="About Company"
                    name="aboutCompany"
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    value={aboutCompany}
                    onChange={handleInputChange}
                    error={!!errors.aboutCompany}
                    helperText={errors.aboutCompany}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                      },
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "250px",
                      height: "180px",
                      backgroundColor: "#f2f3f4",
                      borderRadius: "12px",
                      border: "2px dashed #D9D9D9",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      textAlign: "center",
                      position: "relative",
                    }}
                    onClick={() =>
                      document.getElementById("fileInput1").click()
                    }
                  >
                    {isSubmitted && !file1 ? (
                      <CheckCircleIcon
                        style={{ fontSize: "48px", color: "#4caf50" }}
                      />
                    ) : file1 ? (
                      <>
                        <DescriptionIcon
                          style={{ fontSize: "48px", color: "#DD2F4D" }}
                        />
                        <p
                          style={{
                            marginTop: "8px",
                            fontWeight: "500",
                            color: "#555",
                          }}
                        >
                          {file1.name}
                        </p>
                      </>
                    ) : (
                      <>
                        <CloudUploadIcon
                          style={{ fontSize: "48px", color: "#DD2F4D" }}
                        />
                        <p
                          style={{
                            marginTop: "8px",
                            fontWeight: "500",
                            color: "#555",
                            padding: "6px",
                            borderRadius: "6px",
                            border: "2px solid #D9D9D9",
                          }}
                        >
                          Browse Computer
                        </p>
                      </>
                    )}
                    <input
                      type="file"
                      id="fileInput1"
                      accept=".pdf"
                      onChange={(e) => handleFileChange(e, setFile1)}
                      style={{ display: "none" }}
                    />
                  </Box>
                )}
              </Grid>

              {/* Services Provided Section */}
              <Grid item xs={12}>
                <Typography variant="subtitle1">Services Provided*</Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="servicesType"
                    value={servicesType}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="typing"
                      control={<Radio />}
                      label="Typing"
                    />
                    <FormControlLabel
                      value="upload"
                      control={<Radio />}
                      label="Upload File"
                    />
                  </RadioGroup>
                </FormControl>

                {servicesType === "typing" ? (
                  <TextField
                    label="Services Provided"
                    name="services"
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    value={services}
                    onChange={handleInputChange}
                    error={!!errors.services}
                    helperText={errors.services}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                      },
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "250px",
                      height: "180px",
                      backgroundColor: "#f2f3f4",
                      borderRadius: "12px",
                      border: "2px dashed #D9D9D9",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      textAlign: "center",
                      position: "relative",
                    }}
                    onClick={() =>
                      document.getElementById("fileInput2").click()
                    }
                  >
                    {isSubmitted && !file2 ? (
                      <CheckCircleIcon
                        style={{ fontSize: "48px", color: "#4caf50" }}
                      />
                    ) : file2 ? (
                      <>
                        <DescriptionIcon
                          style={{ fontSize: "48px", color: "#DD2F4D" }}
                        />
                        <p
                          style={{
                            marginTop: "8px",
                            fontWeight: "500",
                            color: "#555",
                          }}
                        >
                          {file2.name}
                        </p>
                      </>
                    ) : (
                      <>
                        <CloudUploadIcon
                          style={{ fontSize: "48px", color: "#DD2F4D" }}
                        />
                        <p
                          style={{
                            marginTop: "8px",
                            fontWeight: "500",
                            color: "#555",
                            padding: "6px",
                            borderRadius: "6px",
                            border: "2px solid #D9D9D9",
                          }}
                        >
                          Browse Computer
                        </p>
                      </>
                    )}
                    <input
                      type="file"
                      id="fileInput2"
                      accept=".pdf"
                      onChange={(e) => handleFileChange(e, setFile2)}
                      style={{ display: "none" }}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserProfile;
