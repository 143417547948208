import React, { useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Divider,
  Button,
  TextField,
} from "@mui/material";
import axios from "axios";
import Sidebar from "./sidebar";
import { useNavigate } from "react-router-dom";

const Generatedcontent = ({ data, id }) => {
  const [inputValue, setInputValue] = useState(""); // State for the input value
  const [error, setError] = useState(""); // State for error message
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(true); // Initial state for sidebar
  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };

  console.log(data);
  if (!data) {
    return (
      <Typography sx={{ color: "red", textAlign: "center" }}>
        No data available
      </Typography>
    );
  }

  const handleApprove = (content) => {
    console.log("Approving content:", content);
    navigate("/leads");
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (error) {
      setError(""); // Clear the error if the user starts typing
    }
  };

  return (
    <>
      <Box
        sx={{
          margin: "20px auto",
          width: sidebarOpen
            ? { xs: "0", sm: "100%" }
            : { xs: "100%", sm: "100%" },
          ml: sidebarOpen ? { xs: "0", sm: "290px" } : { xs: "0", sm: "250px" },
          padding: "24px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "12px",
          background: "linear-gradient(135deg, #f8f9fa, #e9ecef)",
          boxShadow: "0 6px 20px rgba(0, 0, 0, 0.15)",
        }}
      >
        {/* Subject */}
        <Box
          sx={{
            padding: "12px 0",
            textAlign: "center",
            borderBottom: "2px solid #dee2e6",
            mb: 2,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              color: "#3f51b5",
              textTransform: "uppercase",
              letterSpacing: "1px",
            }}
          >
            {data?.subject}
          </Typography>
        </Box>

        {/* Summary */}
        <Box
          sx={{
            margin: "20px 0",
            padding: "16px",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 500, color: "#333", fontSize: "16px" }}
          >
            <span
              style={{
                fontWeight: "bold",
                color: "#009688",
                marginRight: "5px",
              }}
            >
              Subject:
            </span>
            {data?.subject}
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: 500, color: "#333", fontSize: "16px" }}
          >
            <span
              style={{
                fontWeight: "bold",
                color: "#009688",
                marginRight: "5px",
              }}
            >
              Summary:
            </span>
            {data?.summary}
          </Typography>
        </Box>

        {data &&
        Object.keys(data).filter((key) => key.startsWith("content_")).length >
          0 ? (
          Object.keys(data)
            .filter((key) => key.startsWith("content_"))
            .map((key, index) => (
              <Box
                key={index}
                sx={{
                  padding: "20px",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                  boxShadow: "inset 0 0 12px rgba(0, 0, 0, 0.05)",
                  transition: "transform 0.2s ease",
                  "&:hover": {
                    transform: "scale(1.02)",
                    boxShadow: "inset 0 0 20px rgba(0, 0, 0, 0.1)",
                  },
                  mb: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: "#555", fontSize: "15px", lineHeight: "1.6" }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#3f51b5",
                      marginRight: "5px",
                    }}
                  >
                    Content {index + 1}:
                  </span>
                  {data[key]}
                </Typography>

                {index <
                  Object.keys(data).filter((key) => key.startsWith("content_"))
                    .length -
                    1 && <Divider sx={{ my: 2 }} />}
              </Box>
            ))
        ) : (
          <Typography
            variant="body2"
            sx={{ width: "100%", color: "#999", fontSize: "15px" }}
          >
            No content available.
          </Typography>
        )}

        {/* Action Buttons */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="contained"
            color="success"
            sx={{ width: "60%", textTransform: "none" }}
            onClick={() => handleApprove(data)}
          >
            Approve
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Generatedcontent;
