import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";

const SignIn = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const navigate=useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Reset error state when user starts editing the field
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  {snackbarMessage &&
    setTimeout(() => {
      setSnackbarMessage(""); // Clear the message after 3 seconds
    }, 2000)}

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formErrors = {};
    let isValid = true;

    // Validation
    if (!formData.username) {
      formErrors.username = "username is required";
      isValid = false;
    }
    if (!formData.password) {
      formErrors.password = "Password is required";
      isValid = false;
    }

    if (!isValid) {
      setErrors(formErrors);
      return;
    }

    // Make API call if validation passes
    try {
      const response = await axios.post("https://marketing.jinnhire.in/user/token/", formData); // Replace with your API endpoint
      setSnackbarMessage("Sign In successful!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      localStorage.setItem('firstVisit', 'true');
      localStorage.setItem('accesstoken', response.data.access);
      localStorage.setItem('email',formData.username)
      console.log(response.data)
      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);

    } catch (error) {
      localStorage.setItem('firstVisit', 'true');
      console.error("Error during sign in:", error);
      setSnackbarMessage("Sign In failed. Please check your credentials.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Grid container sx={{ height: "100vh", backgroundColor: "#597EF7" }}>
      {/* Left Section */}
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          backgroundColor: "#597EF7",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          justifySelf: "center",
          alignSelf: "center",
          alignItems: "center",
          padding: 4,
          color: "#fff",
          height: "100%",
        }}
      >
        <Box sx={{width:"70%"}}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "normal",
            marginBottom: 2,
            fontFamily: "Poppins, sans-serif",
          }}
        >
          Boost your business with ease—
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Poppins, sans-serif",
          }}
        >
          <strong style={{ color: "#333", fontWeight: "600px",fontSize:"30px" }}>
            PlugLeads
          </strong>{" "}
          streamlines your marketing journey. Log in to get started!
        </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "20px",
            marginTop: 4,
            backgroundColor: "#FFF",
          }}
        >
          <img
            style={{ padding: "20px" }}
            src="Assets/images/SignUpImage.svg"
            alt="Illustration_SignUp"
          />
        </Box>
      </Grid>

      {/* Right Section */}
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 4,
          borderTopLeftRadius: "40px",
          height: "100%",
          borderBottomLeftRadius: "40px",
          border: "0px solid #000",
          backgroundColor: "#FFFFFF",
          boxShadow: "-4px -2px 2px #555",
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderRadius: "10px",
            padding: 3,
          }}
        >
            <Box sx={{textAlign:'center', alignSelf:'center'}}> 
          <Typography
            variant="h5"
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
              marginBottom: 2,
              fontSize:"30px"
            }}
          >
                Hello again
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "normal",
              marginBottom: 2,
              fontSize:"20px",
              width:'80%',
              justifySelf:'center',
            }}
          >
                Welcome back, before logging in, make 
                sure you enter account correctly
          </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              justifySelf: "center",
              marginTop:2,
            }}
          >
           <TextField
              label="Email"
              name="username"
              variant="standard"
              fullWidth
              margin="normal"
              value={formData.username}
              onChange={handleChange}
              type="text" 
              required
              error={Boolean(errors.username)}
              helperText={errors.username}
              
            />
            <TextField
              label="Password"
              name="password"
              variant="standard"
              fullWidth
              margin="normal"
              value={formData.password}
              onChange={handleChange}
              type={showPassword ? "text" : "password"}
              required
              error={Boolean(errors.password)}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Box sx={{ width: "100%" }}>
              {snackbarMessage && (
                <Alert
                  severity={snackbarSeverity}
                  onClose={() => setSnackbarMessage("")}
                  style={{ marginTop: "10px" }}
                >
                  {snackbarMessage}
                </Alert>
              )}
            </Box>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              sx={{ marginTop: "20px", borderRadius: "20px" }}
            >
              Sign In
            </Button>

             <div style={{ marginTop: "10px", textAlign: "center" }}>
              <span>Don't have an account? </span>
              <Link to="/signup" style={{ textDecoration: "none", color: "#1976d2" }}>
                Register 
              </Link>
            </div>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignIn;
