import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  Card, CardContent, 
  TableRow,
  Grid,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  Tooltip,
  Button,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Sidebar from "./sidebar";
import ClearIcon from "@mui/icons-material/Clear";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axios from "axios";

const Leads = () => {
  // State management
  const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const token =localStorage.getItem('accesstoken')
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true); // Initial state for sidebar
  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };

  const navigate = useNavigate();

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get(
        "https://marketing.jinnhire.in/api/campaign/", {
          headers: {
            Authorization: `Bearer ${token}`, // Add the Bearer token
            
          },
        }
      );
      setCampaigns(response.data);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  // Filtered campaigns based on search term
  const filteredCampaigns = campaigns.filter((campaign) =>
    campaign.campaign_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination
  const paginatedCampaigns = filteredCampaigns.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Handlers
  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Open Modal
  const handleRowClick = (campaign) => {
    setSelectedCampaign(campaign);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCampaign(null);
  };

  const handleNewLead = () => {
    navigate("/newlead");
  };

  return (
    <>
    <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
    <Box sx={{ padding: 3,width: sidebarOpen
      ? { xs: "0", sm: "60%" }
      : { xs: "100%", sm: "90%" },
    ml: sidebarOpen ? { xs: "0", sm: "230px" } : { xs: "0", sm: "70px" },
       }}>
      {/* Header */}
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          padding: 2,
          fontFamily: "Poppins",
          textAlign: "center",
          color: "#333",
        }}
      >
        Leads
      </Typography>

      {/* Search Form */}
      <form
        onSubmit={(e) => e.preventDefault()}
        style={{
          fontFamily: "Poppins",
          fontWeight: "400",
          marginTop: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          width: "fit-content",
          padding: "40px",
          border: "1px solid #ccc",
          borderRadius: "10px",
          backgroundColor: "#f9ffff",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          sx={{
            marginBottom: 2,
            width: "100%",
            maxWidth: 1200,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search campaigns..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm && (
                    <IconButton onClick={() => setSearchTerm("")}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            sx={{
              backgroundColor: "#f1f1f1",
              marginBottom: 2,
              width: "100%",
              maxWidth: "500px",
              borderRadius: "5px",
            }}
          />
        </Box>

        {/* Campaign Table */}
        <TableContainer
          component={Paper}
          sx={{ marginBottom: 18, width: "100%", maxWidth: 1200 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <strong>SL.No</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Campaign Name</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Campaign Status</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Start Date</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>End Date</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCampaigns.length > 0 ? (
                paginatedCampaigns.map((campaign, index) => (
                  <TableRow key={campaign.id} onClick={() => handleRowClick(campaign)}>
                    <TableCell align="center">{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>
                      <Tooltip title={campaign.campaign_name} arrow>
                        <span>
                          {campaign.campaign_name.length > 50
                            ? `${campaign.campaign_name.slice(0, 50)}...`
                            : campaign.campaign_name}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{campaign.status}</TableCell>
                    <TableCell align="center">
                      {new Date(campaign.start_date).toLocaleDateString("en-US")}
                    </TableCell>
                    <TableCell align="center">
                      {new Date(campaign.end_date).toLocaleDateString("en-US")}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No campaigns found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredCampaigns.length}
            rowsPerPage={rowsPerPage}
            page={page}
            sx={{
              overflow: "hidden",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 2,
              padding: 0,
            }}
            marginLeft={"20px"}
            width={"100%"}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page:"
          />
        </TableContainer>
      </form>

      {/* Modal for Campaign Details */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              bgcolor: "background.paper",
              borderRadius: "10px",
              boxShadow: 24,
              padding: 3,
            }}
          >
           {selectedCampaign && (
        <div style={{display:"flex",alignItems:'center', flexDirection:"column", backgroundColor: '#f5f5f5', borderRadius: '10px', padding: '16px', width: '80%', margin: '0 auto' }}>
          <Typography variant="h6" sx={{justifySelf:"center", width:"fit-content", fontWeight: 'bold', color: 'primary.main', marginBottom: 2 }}>
            Campaign Details
          </Typography>

          <Card sx={{ width:"80%", padding: 2, borderRadius: '8px', boxShadow: 3, display:"flex", flexDirection:"column",}}>
            <CardContent sx={{display:"flex", flexDirection:"column", }}>
              <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 1,whiteSpace: 'normal',wordWrap: 'break-word', }}>
                <strong>Name:</strong> {selectedCampaign.campaign_name}
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 1 }}>
                <strong>Status:</strong> {selectedCampaign.status}
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 1 }}>
                <strong>Start Date:</strong> {new Date(selectedCampaign.start_date).toLocaleDateString("en-US")}
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 1 }}>
                <strong>End Date:</strong> {new Date(selectedCampaign.end_date).toLocaleDateString("en-US")}
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 1 }}>
                <strong>Campaign Type:</strong> {selectedCampaign.campaign_type}
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 1 }}>
                <strong>Target Type:</strong> {selectedCampaign.campaign_target_type}
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 1 }}>
                <strong>Promotion:</strong> {selectedCampaign.promotion}
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 1 }}>
                <strong>Time of Day:</strong> {selectedCampaign.time_of_day}
              </Typography>
            </CardContent>
          </Card>

          <Button 
            onClick={handleCloseModal} 
            sx={{
              backgroundColor: 'red',
              width:"50%",
              color: '#fff',
              '&:hover': {
                backgroundColor: 'darkred',
              },
              marginTop: 2
            }}
          >
            Close
          </Button>
        </div>
      )}
          </Box>
        </Fade>
      </Modal>

     
    </Box>
    </>
  );
};

export default Leads;
