import React from 'react';
import './App.css';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PromptGenerator from './components/promtgenerator';
import Createcampaign from "./components/Createcampaign"
import Home from './components/Home';
import Generatedcontent from './components/Generatedcontent';
import Leads from './components/Leads';
import NewLead from './components/newlead';
import SignUp from './components/Authorization/signup';
import SignIn from './components/Authorization/signIn';
import Dashboard from './components/dashboard';
import UserProfile from './components/userprofile';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          {/* Authentication Routes */}
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          
          {/* Dashboard Route */}
          <Route path="/dashboard" element={<Dashboard />} />
          
          {/* Main Routes */}
          <Route path="*" element={<SignUp />} />
          <Route path="/createcampaign" element={<Createcampaign />} /> {/* Fixed typo */}
          <Route path="/promptgen" element={<PromptGenerator />} />
          <Route path="/gen" element={<Generatedcontent />} />
          <Route path="/leads" element={<Leads />} />
          <Route path="/newlead" element={<NewLead />} />
          <Route path="/userprofile" element={<UserProfile />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
