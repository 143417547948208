import React, { useState, useEffect  } from "react";
import {useNavigate} from 'react-router-dom';
import {
  Container,
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  Typography,
  FormControl,
  Checkbox,
  ListItemText,
 
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import axios from "axios";
import Grid from "@mui/material/Grid";
import locations from "./locations.json";
import Industry from "./Industries.json";
import Cjobtitles from "./Jobtitle.json";
import Autocomplete from "@mui/material/Autocomplete";
import Role from "./Roles.json";

const connectionList = [
  { id: "1", val: "1st degree connections" },
  { id: "2", val: "2nd degree connections" },
  { id: "3", val: "3rd+ degree connections" },
];

function NewLead() {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const [openModal, setOpenModal] = useState(false);
  
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedHeadCount, setSelectedHeadCount] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [selectedFunction, setSelectedFunction] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [currentjob, setCurrentjob] = useState("");
  const [campaignDetails, setCampaignDetails] = useState({
    department: "",
    designation: "",
    country: "",
    industry: "",
  });
  const [positionlevel, setPositionlevel] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [pastjobtitle, setPastjobtitle] = useState("");
  const [currentcompanyyears, setCurrentcompanyyears] = useState("");
  const [currentposyears, setCurrentposyears] = useState("");
  const [geography, setGeography] = useState("");
  const [yearsofexp, setYearsofexp] = useState("");
  const [connections, setConnections] = useState("");
  const [campaign_type, setcampaignType] = useState("");
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Renamed from selectedCampaign
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [AllIndustries, setAllIndustries] = useState([]); // Store all industries fetched from API
  const [Industries, setIndustries] = useState([]);
  const [headCountArray, setHeadCountArray] = useState([]);
  const [industries, setindustries] = useState([]);

  // const postData = async (modelName, values) => {
  //   try {
  //     // Initialize an array to store the IDs of the created items
  //     const ids = [];

  //     // Loop through the values array and post each value to the API
  //     for (const value of Industry) {
  //       const response = await axios.post(
  //         `https://marketing.jinnhire.in/api/industries/`,
  //         value,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       if (response.status === 201) {
  //         // Check if the response status is successful
  //         console.log("Data posted successfully:", response.data);
  //         ids.push(response.data.id); // Store the id of the created value
  //       } else {
  //         console.error("Failed to post data:", response);
  //       }
  //     }

  //     // Return the array of created IDs
      
  //   } catch (error) {
  //     console.error("Error posting data:", error);
  //   }
  // };
 

  useEffect(() => {
    const results = campaigns.filter((campaign) =>
      campaign.campaign_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCampaigns(results);
  }, [searchTerm, campaigns]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Change this based on your requirements
  const totalItems = 100; // Total number of items (for example, campaigns)

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // campaign usestates
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [industry, setIndustry] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [promotion, setPromotion] = useState("");
  // const [campaignName, setCampaignName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [leadsPerDay, setLeadsPerDay] = useState("");
  const [leadsRequired, setLeadsRequired] = useState("");
  const [numFollowups, setNumFollowups] = useState();
  const [geographyInput, setGeographyInput] = useState("");
  const [industryInput, setIndustryInput] = useState("");
  const [pastJobTitle, setPastJobTitle] = useState("");
  const [roles, setRoles] = useState(""); // Add this line
  const functions = [{ val: "Marketing" }, { val: "Sales" }];
  const [error, setError] = useState(false);
  const [positionLevelArray, setPositionLevelArray] = useState([]);
  const [POS_Level, setPOS_Level] = useState([]);
  const [headCountOptions, setHeadCountOptions] = useState([]);
  const [companyHeadCountInput, setCompanyHeadCountInput] = useState([]); // Store input values, if needed
  const [loading, setLoading] = useState(false);
  const [headCountIds, setHeadCountIds] = useState([]);
  const navigate = useNavigate();

  const companyTypeOptions = [
    { val: "Public Company" },
    { val: "Privately Held" },
    { val: "Non Profit" },
    { val: "Educational Institution" },
    { val: "Partnership" },
    { val: "Self Employed" },
    { val: "Self Owned" },
    { val: "Government Agency" },
  ];

  // State variables for company type
  const [companyTypeInput, setCompanyTypeInput] = useState("");

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get(
        "https://marketing.jinnhire.in/api/mail-campaigns/"
      );
      setCampaigns(response.data);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  // useEffect(() => {
  //   console.log(startDate,"SD");
  //   console.log(endDate,"ED")
  //   if (startDate && endDate) {
  //     calculateNumFollowups(startDate, endDate);
  //   }
  // }, [startDate, endDate]);

  function calculateNumFollowups(start, end) {
    const timeDifference = end - start;
    const weeksDifference = Math.floor(timeDifference / (7 * 24 * 60 * 60 * 1000));
    console.log(weeksDifference,"LOG value");
    return weeksDifference;
  }
  


  const fetchCampaignDetails = async (campaignId) => {
    try {
      const response = await axios.get(
        `https://marketing.jinnhire.in/api/mail-campaigns/${campaignId}/view-lead-request-details/`
      );
      console.log(response.data[0], "lead data");
      // setCampaignDetails(response.data[0]);
      // Populate fields with existing data
      setcampaignType(response.data[0].campaign_type || "");
      setSelectedHeadCount(response.data[0].company_headcount || "");
      setCompanyType(response.data[0].company_type || "");
      setCompanyName(response.data[0].company_name || "");
      setSelectedFunction(response.data[0].function || "");
      setCurrentjob(response.data[0].designation || "");
      setPositionlevel(response.data[0].seniority_level || "");
      setPastjobtitle(response.data[0].past_job_title || "");
      setCurrentcompanyyears(response.data[0].years_in_current_company || "");
      setCurrentposyears(response.data[0].years_in_current_position || "");
      setCountry(response.data[0].country || "");
      setState(response.data[0].state || "");
      setIndustry(response.data[0].industry || "");
      setDepartment(response.data[0].department || "");
      setDesignation(response.data[0].currentjob || "");
      setPromotion(response.data[0].promotion || "");
      setstartDate(response.data[0].start_date || "");
      setendDate(response.data[0].end_date || "");
      setNumFollowups(response.data[0].num_followups );
      setGeography(response.data[0].geography || "");
      setIndustries(response.data[0].industry || "");
      setYearsofexp(response.data[0].years_of_experience || "");
      setConnections(response.data[0].connection || "");
    } catch (error) {
      console.error("Error fetching campaign details:", error);
      // Clear fields if no data
      setCompanyName("");
      setcampaignType("");
      setCountry("");
      setState("");
      setIndustry("");
      setDepartment("");
      setDesignation("");
      setPromotion("");
      setstartDate("");
      setendDate("");
      setNumFollowups("");
      setCompanyType("");
      setSelectedHeadCount("");
      setConnections("");
      setCurrentjob("");
      setSelectedFunction("");
      setGeography("");
      setIndustries("");
      setPastjobtitle("");
      setPositionlevel("");
      setCurrentcompanyyears("");
      setCurrentposyears("");
      setYearsofexp("");
    }
  };

  // Fetch campaigns from API
  useEffect(() => {
    fetchCampaigns();
  }, []);

  // useEffect(() => {
  //   const formattedCampaignName = [
  //     geography, // Geography
  //     state, // State
  //     Industries, // Industry
  //     selectedFunction, // Function
  //     currentjob, // Current Job Title
  //     promotion, // Promotion
  //   ]
  //     .filter(Boolean) // Filter out falsy values
  //     .join("_"); // Join values with an underscore

  //   setCampaignName(formattedCampaignName);
  // }, [geography, state, Industries, selectedFunction, currentjob, promotion]);

  // Handle tab change
  

 
  // Handle campaign selection
  const handleCampaignSelect = (event) => {
    const selectedId = event.target.value;
    setSelectedCampaign(selectedId);
    fetchCampaignDetails(selectedId);

    const selectedCampaignDetails = campaigns.find(
      (campaign) => campaign.id === selectedId
    );
    setCampaignDetails(selectedCampaignDetails);
    // console.log(campaignDetails,"detail");
    // console.log(selectedCampaignDetails,"SSD")
    if (selectedCampaignDetails) {
      setCompanyType(selectedCampaignDetails.companyType);
    } else {
      setCompanyType("");
    }
  };

  const handleLeadsChange = (e) => {
    const value = e.target.value;
    setLeadsRequired(value);

    // Check if the input is a valid number and greater than 0
    if (value === "" || Number(value) <= 0) {
      setError(true);
    } else {
      setError(false);
    }
  };
  // Get the current time
  const now = new Date();

  // Format time_of_day as HH:MM:SS
  const time_of_day = now.toTimeString().split(" ")[0];

  const convertToISO = (date) => {
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime()) ? null : parsedDate.toISOString();
  };


  
  // Creating campaign name
  const handleCreateCampaign = async () => {
   
    try {
     
      
      const startDate = new Date(2022, Math.floor(Math.random() * 12), Math.floor(Math.random() * 28) + 1); // Random date in 2022
      const endDate = new Date(2023, Math.floor(Math.random() * 12), Math.floor(Math.random() * 28) + 1);   // Random date in 2023
      // Ensure start and end dates are in ISO 8601 format
      const startDateISO = convertToISO(startDate); // Use the state value
      const endDateISO = convertToISO(endDate); // Use the state value

      // Ensure startDate and endDate are provided
      

      // Ensure leadsPerDay and leadsRequired are integers
      const leadsRequiredInt = parseInt(leadsRequired, 10);
      const leadsPerDayInt = parseInt(leadsPerDay, 10);
    
// Convert ISO date strings to Date objects
const startDate1 = new Date(startDateISO);
const endDate1 = new Date(endDateISO);

// Calculate the time difference in milliseconds
const timeDifference = endDate1 - startDate1;

// Convert milliseconds to weeks (1 week = 7 * 24 * 60 * 60 * 1000 ms)
const weeksBetween = Math.floor(timeDifference / (7 * 24 * 60 * 60 * 1000));

console.log(weeksBetween, "weeks between start_date and end_date"); // Logs the number of weeks
    
      // Define the scheduled_at (current date + desired scheduling time)
      const scheduled_at = new Date().toISOString();
      const lead_request_payload = {
        company_name: companyName || "Nexii Consulting Solutions",
        geography: geography || "",
        state: state || "",
        industry: industries || "",
        function: selectedFunction || "",
        current_job_title: currentjob || "",
        connection: connections || null,
        years_in_current_company: currentcompanyyears || null,
        years_in_current_position: currentposyears || null,
        yearsofexp: yearsofexp || null,
        number_of_leads_required: leadsRequiredInt, 
        seniority_level: positionLevelArray.map((level) => level.id),
        head_count: headCountIds,
      };

      // Make the POST request to the API
      const response = await fetch(
        "https://marketing.jinnhire.in/api/campaign/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...lead_request_payload, 
          }),
        }
      );

      const responseBody = await response.text();

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${responseBody}`);
      }

      let result = JSON.parse(responseBody);

      alert("Campaign created successfully!");
      fetchCampaigns();
      navigate('/home');
    } catch (error) {
      alert("There was an error creating the campaign: " + error.message);
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    setOpenModal(true);
  };
 
  const handleConfirm = async (e) => {
    // Perform submission logic here
    e.preventDefault(); // Prevent the form from refreshing

    // Ensure a campaign is selected
    if (!selectedCampaign) {
      alert("Please select a campaign");
      return;
    }
    // Construct the dynamic URL using the selected campaign ID
    const campaignId = selectedCampaign;
    const url = `https://marketing.jinnhire.in/api/mail-campaigns/${campaignId}/add-lead-request-details/`;

    // Create a payload with the data you want to send
    const payload = {
      campaign: selectedCampaign,
      company_headcount: selectedHeadCount || null,
      company_type: companyType || null,
      connection: connections || null,
      current_job_title: currentjob || null,
      function: selectedFunction || null,
      geography: geography || null,
      industry: Industries || null,
      number_of_leads: campaignDetails.number_of_leads_required || "--",
      number_of_leads_uploaded:
        campaignDetails.number_of_leads_uploaded || "--",
      past_job_title: pastjobtitle || null,
      seniority_level: positionlevel || null,
      years_in_current_company: currentcompanyyears || null,
      years_in_current_position: currentposyears || null,
      years_of_experience: yearsofexp || null,
    };
    console.log("payload Data:", payload);

    try {
      // Make the axios POST request
      const response = await axios.post(url, payload);

      // Handle success
      if (response.status === 201) {
        alert("submission is successfull!");
      }
    } catch (error) {
      // Handle error
      console.error("Error submitting data:", error);
      alert("There was an error submitting the data." + error.message);
    }
    console.log("Data submitted");
    setOpenModal(false);
  };

  const handleEdit = () => {
    setOpenModal(false);
  };

  const paginatedCampaigns = filteredCampaigns.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  useEffect(() => {
    const fetchPositionLevels = async () => {
      try {
        const response = await axios.get(
          "https://marketing.jinnhire.in/api/seniority_levels/"
        );
        setPOS_Level(response.data); // Assuming the API returns an array of position levels
      } catch (error) {
        console.error("Error fetching position levels:", error);
      }
    };

    fetchPositionLevels();
  }, []);

  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const response = await axios.get(
          "https://marketing.jinnhire.in/api/industries/"
        );
        // Log the response to see the fetched data
        setAllIndustries(response.data); // Assuming the API returns an array of industries
      } catch (error) {
        console.error("Error fetching industries:", error);
      }
    };

    fetchIndustries();
  }, []);

  useEffect(() => {
    const fetchHeadCountOptions = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://marketing.jinnhire.in/api/company_head_counts/"
        );
        setHeadCountOptions(response.data); // Assuming the response contains an array of objects
      } catch (error) {
        console.error("Error fetching head count options:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHeadCountOptions();
  }, []);

  // Handle selection change

  const handleChange = (event, field) => {
    const { value } = event.target;

    if (field === "positionLevel") {
      setPositionLevelArray(value); // Update selected position levels
    } else if (field === "headCount") {
      if (value && Array.isArray(value)) {
        const selectedIds = value.map((item) => item.id);
        setHeadCountArray(value); // Keep the full objects in state for UI purposes
        setHeadCountIds(selectedIds); // Update the selected IDs
      }
    }
  };

  const handleIndustryChange = (event, newValue) => {
    // Extract the selected industry IDs from `newValue`
    const selectedIndustryIds = newValue.map((option) => option.id);
    setindustries(selectedIndustryIds);
    // Optionally, you can store the full objects for external use
    setIndustryInput(newValue);
  };

  return (
    <div>
      <Container>
        <Box sx={{ width: "100%", marginTop: 3 }}>
            <Box
              sx={{
                padding: 3,
                backgroundColor: "#f9fafb",
                borderRadius: "10px",
                boxShadow: 2,
              }}
            >
              {/* Main form starts */}
              <form>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center", // Center the items vertically
                    justifyContent: "center", // Center the items horizontally
                  }}
                >
                  <CreateIcon
                    sx={{ color: "#1871ac", fontSize: "22px", marginRight: 1 }}
                  />{" "}
                  {/* Add the icon here */}
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 600,
                      color: "#1871ac",
                      margin: "0px",
                    }}
                  >
                    Create New Lead
                  </Typography>
                </Box>

                <Box
                  sx={{
                    marginTop: 4,
                    padding: 4, // Add padding around the content
                    backgroundImage:
                      "linear-gradient(to bottom, #FFF9E7, #FFFFFF)",
                    borderRadius: "10px", // Rounded corners
                    boxShadow: 2, // Subtle shadow for depth
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      marginBottom: 2,
                      alignItems: "center", // Center the items vertically
                      justifyContent: "start", // Center the items horizontally
                    }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 600,
                        color: "#00416A",
                        margin: "0px",
                      }}
                    >
                       Details
                    </Typography>
                    <InfoOutlinedIcon
                      dedIcon
                      sx={{ color: "#00416A", fontSize: "20px", marginLeft: 1 }}
                    />{" "}
                    {/* Add the icon here */}
                  </Box>

                  <Grid container spacing={4}>
                    {/* Location Field */}
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 400,
                          fontSize: "18px",
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Location:
                      </Typography>
                      <Autocomplete
                        options={locations}
                        getOptionLabel={(option) => option.val || ""}
                        value={
                          locations.find(
                            (location) => location.val === geography
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          setGeography(newValue ? newValue.val : "");
                          setGeographyInput(newValue ? newValue.val : ""); // Update state for outside input
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Country"
                            variant="outlined"
                            fullWidth
                            sx={{
                              backgroundColor: "#f7f8fa",
                              borderRadius: "10px",
                              "& .MuiInputLabel-root": {
                                color: "#555",
                              },
                            }}
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.val === value.val
                        }
                        noOptionsText="- - No options - -"
                      />
                    </Grid>

                  
                    {/* State Field */}
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 400,
                          fontSize: "18px",
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        State:
                      </Typography>
                      <TextField
                        placeholder="Enter State"
                        fullWidth
                        required
                        variant="outlined"
                        sx={{
                          backgroundColor: "#f7f8fa",
                          borderRadius: "10px",
                          "& .MuiInputLabel-root": {
                            color: "#555",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& input": {
                              textAlign: "start", // Align the input text
                            },
                            "& fieldset": {
                              borderColor: "#d1d5db", // Custom border color
                            },
                            "&:hover fieldset": {
                              borderColor: "#a0aec0", // Hover color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#3f51b5", // Focused color
                            },
                            ...(isSubmitted &&
                              !state && {
                                "& fieldset": {
                                  borderColor: "red", // Red border when field is empty
                                },
                              }),
                          },
                        }}
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        error={isSubmitted && !state} // Set error if state is empty and form has been submitted
                        helperText={
                          isSubmitted && !state ? "State is required" : ""
                        } // Show error message
                      />
                    </Grid>

                    {/* <Grid item xs={12} md={6}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 400,
                        fontSize: "18px",
                        color: "#555",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      Promotion:
                    </Typography>
                    <TextField
                      placeholder="Enter Promotion"
                      fullWidth
                      required
                      variant="outlined"
                      sx={{
                        backgroundColor: "#f7f8fa",
                        borderRadius: "10px",
                        "& .MuiInputLabel-root": {
                          color: "#555",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& input": {
                            textAlign: "start", // Center align the input text
                          },
                          "& fieldset": {
                            borderColor: "#d1d5db", // Custom border color
                          },
                          "&:hover fieldset": {
                            borderColor: "#a0aec0", // Hover color
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#3f51b5", // Focused color
                          },
                        },
                      }}
                      value={promotion}
                      onChange={(e) => setPromotion(e.target.value)}
                    />
                  </Grid> */}

                   
                    
                    {/* Industry Field */}
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 400,
                          fontSize: "18px",
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Industry:
                      </Typography>
                      <Autocomplete
                        multiple
                        options={AllIndustries} // Options fetched from API
                        getOptionLabel={(option) => option.name || ""} // Assuming `name` is the name of the industry
                        value={AllIndustries.filter((industry) =>
                          industries.includes(industry.id)
                        )} // Filter based on selected IDs
                        onChange={handleIndustryChange} // Update selected values on change
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Industry"
                            variant="outlined"
                            fullWidth
                            sx={{
                              backgroundColor: "#f7f8fa",
                              borderRadius: "10px",
                              "& .MuiInputLabel-root": {
                                color: "#555",
                              },
                              "& .MuiOutlinedInput-root": {
                                "& input": {
                                  textAlign: "start",
                                },
                                "& fieldset": {
                                  borderColor: "#d1d5db",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#a0aec0",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#3f51b5",
                                },
                              },
                            }}
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        } // Ensure correct comparison for selected value
                        noOptionsText="- - No options - -"
                      />
                    </Grid>

                    {/* Company Head Count Field */}
                    <Grid item xs={12} md={6}>
                      <h4
                        style={{
                          fontWeight: 400,
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Company Head Count:
                      </h4>
                      <FormControl fullWidth>
                        <Select
                          labelId="head-count-label"
                          multiple
                          value={headCountArray} // Ensure value is the array of selected objects
                          onChange={(e) => handleChange(e, "headCount")}
                          renderValue={
                            (selected) =>
                              selected.map((count) => count.range).join(", ") // Display selected ranges as comma-separated
                          }
                          variant="outlined"
                          sx={{
                            backgroundColor: "#f7f8fa",
                            borderRadius: "5px",
                          }}
                        >
                          {headCountOptions.map((count) => (
                            <MenuItem key={count.id} value={count}>
                              {" "}
                              {/* Use the whole object as the value */}
                              <Checkbox
                                checked={headCountArray.some(
                                  (item) => item.id === count.id
                                )}
                              />
                              <ListItemText primary={count.range} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* Company Type Field */}
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 400,
                          fontSize: "18px",
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Company Type:
                      </Typography>
                      <Autocomplete
                        options={companyTypeOptions}
                        getOptionLabel={(option) => option.val || ""}
                        value={
                          companyTypeOptions.find(
                            (type) => type.val === companyType
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          setCompanyType(newValue ? newValue.val : "");
                          setCompanyTypeInput(newValue ? newValue.val : ""); // Update state for outside input
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Company Type"
                            variant="outlined"
                            fullWidth
                            sx={{
                              backgroundColor: "#f7f8fa",
                              borderRadius: "10px",
                              "& .MuiInputLabel-root": {
                                color: "#555",
                              },
                              "& .MuiOutlinedInput-root": {
                                "& input": {
                                  textAlign: "start", // Center align the input text
                                },
                                "& fieldset": {
                                  borderColor: "#d1d5db", // Custom border color
                                },
                                "&:hover fieldset": {
                                  borderColor: "#a0aec0", // Hover color
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#3f51b5", // Focused color
                                },
                              },
                            }}
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.val === value.val
                        }
                        noOptionsText="- - No options - -"
                      />
                    </Grid>

                  </Grid>
                </Box>

                <Box
                  sx={{
                    marginTop: 4,
                    padding: 4, // Add padding around the content
                    backgroundImage:
                      "linear-gradient(to right, #FFEEEE, #FFFFFF)",
                    borderRadius: "10px", // Rounded corners
                    boxShadow: 2, // Subtle shadow for depth
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center", // Center the items vertically
                      justifyContent: "start",
                      marginBottom: 2, // Center the items horizontally
                    }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 600,
                        color: "#1871ac",
                        margin: "0px",
                      }}
                    >
                      Role Details
                    </Typography>
                    <AccountCircleOutlinedIcon
                      sx={{ color: "#1871ac", fontSize: "22px", margin: 1 }}
                    />{" "}
                    {/* Add the icon here */}
                  </Box>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 400,
                          fontSize: "18px",
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Function:
                      </Typography>
                      <Autocomplete
                        options={Role}
                        getOptionLabel={(option) => option.val || ""}
                        value={
                          Role.find(
                            (industry) => industry.val === selectedFunction
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          setSelectedFunction(newValue ? newValue.val : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Job Function"
                            variant="outlined"
                            fullWidth
                            sx={{
                              backgroundColor: "#f7f8fa",
                              borderRadius: "10px",
                              "& .MuiInputLabel-root": {
                                color: "#555",
                              },
                              "& .MuiOutlinedInput-root": {
                                "& input": {
                                  textAlign: "start", // Center align the input text
                                },
                                "& fieldset": {
                                  borderColor: "#d1d5db", // Custom border color
                                },
                                "&:hover fieldset": {
                                  borderColor: "#a0aec0", // Hover color
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#3f51b5", // Focused color
                                },
                              },
                            }}
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.val === value.val
                        }
                        noOptionsText="- - No options - -"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 400,
                          fontSize: "18px",
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Current Job Title:
                      </Typography>
                      <Autocomplete
                        options={
                          Cjobtitles.filter((jobTitle) =>
                            jobTitle.val
                              .toLowerCase()
                              .includes(selectedFunction.toLowerCase())
                          ).length > 0
                            ? Cjobtitles.filter((jobTitle) =>
                                jobTitle.val
                                  .toLowerCase()
                                  .includes(selectedFunction.toLowerCase())
                              ) // Filtered job titles based on selected function
                            : Cjobtitles // Show all job titles if no filter matches
                        }
                        getOptionLabel={(option) => option.val || ""}
                        value={
                          Cjobtitles.find(
                            (industry) => industry.val === currentjob
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          setCurrentjob(newValue ? newValue.val : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Job Title"
                            variant="outlined"
                            fullWidth
                            sx={{
                              backgroundColor: "#f7f8fa",
                              borderRadius: "10px",
                              "& .MuiInputLabel-root": {
                                color: "#555",
                              },
                              "& .MuiOutlinedInput-root": {
                                "& input": {
                                  textAlign: "start", // Center align the input text
                                },
                                "& fieldset": {
                                  borderColor: "#d1d5db", // Custom border color
                                },
                                "&:hover fieldset": {
                                  borderColor: "#a0aec0", // Hover color
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#3f51b5", // Focused color
                                },
                              },
                            }}
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.val === value.val
                        }
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <h4
                        style={{
                          fontWeight: 400,
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Seniority Level:
                      </h4>
                      <FormControl fullWidth>
                        <Select
                          labelId="position-level-label"
                          multiple
                          value={positionLevelArray} // Ensure value is always an array
                          onChange={(e) => handleChange(e, "positionLevel")} // Corrected: use only event and field
                          renderValue={
                            (selected) =>
                              selected.map((level) => level.level).join(", ") // Display selected levels as comma-separated
                          }
                          variant="outlined"
                          sx={{
                            backgroundColor: "#f7f8fa",
                            borderRadius: "5px",
                          }}
                        >
                          {POS_Level.map((level) => (
                            <MenuItem key={level.id} value={level}>
                              {" "}
                              {/* Use the full object as the value */}
                              <Checkbox
                                checked={positionLevelArray.some(
                                  (item) => item.id === level.id
                                )}
                              />
                              <ListItemText primary={level.level} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                   
                  </Grid>
                </Box>

                {/* BEST PATH-IN Section */}
                <Box
                  sx={{
                    marginTop: 4,
                    padding: 4, // Add padding around the content
                    backgroundImage:
                      "linear-gradient(to left, #D6E4FE, #EDF5FF)",
                    borderRadius: "10px", // Rounded corners
                    boxShadow: 2, // Subtle shadow for depth
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center", // Center the items vertically
                      justifyContent: "start",
                      marginBottom: 2,
                    }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 600,
                        color: "#00416A",
                        margin: "0px",
                      }}
                    >
                      Lead Details
                    </Typography>
                    <LightbulbOutlinedIcon
                      dedIcon
                      sx={{ color: "#00416A", fontSize: "20px", marginLeft: 1 }}
                    />{" "}
                    {/* Add the icon here */}
                  </Box>
                  <Grid container spacing={4}>
                    {/* Leads Required */}
                    <Grid item xs={12} md={6}>
                      <h4
                        style={{
                          fontWeight: 400,
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Number Of Leads Required
                      </h4>
                      <TextField
                        type="text" // Changed from "number" to "text" to remove spinner arrows
                        placeholder="Enter Leads Required"
                        fullWidth
                        required
                        value={leadsRequired}
                        onChange={(e) => {
                          // Only allow numeric input
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            // This regex allows only digits (numbers)
                            setLeadsRequired(value);
                          }
                        }}
                        sx={{ backgroundColor: "#f7f8fa", borderRadius: "5px" }}
                      />
                    </Grid>

                    {/* Number of Followups */}
                    <Grid item xs={12} md={6}>
                      <h4
                        style={{
                          fontWeight: 400,
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                       Leads Per Day:
                      </h4>
                      <TextField
                        type="text"
                        placeholder="Leads Per Day"
                        fullWidth
                        required
                        value={leadsPerDay}
                        onChange={(e) => {
                          // Only allow numeric input
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            // This regex allows only digits (numbers)
                            setLeadsPerDay(value);
                          }
                        }}
                        sx={{ backgroundColor: "#f7f8fa", borderRadius: "5px" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <h4
                        style={{
                          fontWeight: 400,
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Connections:
                      </h4>
                      <Select
                        fullWidth
                        value={connections}
                        onChange={(e) => setConnections(e.target.value)}
                        displayEmpty
                        sx={{ backgroundColor: "#f7f8fa", borderRadius: "5px" }}
                      >
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        {connectionList.map((item) => (
                          <MenuItem key={item.val} value={item.val}>
                            {item.val}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Box>

                {/* Submit Button */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center", // Centers the button horizontally
                    marginTop: 6,
                    width: "100%", // Full width of the container
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      width: "350px", // Set the button width to 350px
                      padding: "12px 48px",
                      fontWeight: 600,
                      textTransform: "none",
                      backgroundImage:
                        "linear-gradient(to left, #5C76B2, #3561C7)",
                      "&:hover": {
                        backgroundImage:
                          "linear-gradient(to left, #2A3BD2, #216AF9)",
                      },
                    }}
                    onClick={handleCreateCampaign}
                  >
                    Create Lead
                  </Button>
                </Box>
              </form>
            </Box>
        </Box>
      </Container>
    </div>
  );
}

export default NewLead;
